import React, { useEffect } from "react"
import { useSetLocationData } from "./LocationContext"

// This hook is used to update the location data in the context which is used to determine the active state of the header links.
export function useLocationData(pathname) {
  const setLocationData = useSetLocationData()
  useEffect(() => {
    setLocationData(prev => ({
      ...prev,
      pathname: pathname,
    }))
  }, [pathname])
}
